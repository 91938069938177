html {
  background-color: black;
}
body,
html {
  height: 100%;
  padding: 0;
  margin: 0 !important;
}
body {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: auto !important;
  background: none;
}
article {
  padding: 1em;
  color: white;
}
article i {
  color: inherit;
}
img,
ul,
li,
p {
  display: inline-block;
  margin: 0 1em;
  margin: 0 1vw;
  vertical-align: middle;
  line-height: 1;
}
ul {
  display: block;
  margin: 0;
  padding: 0;
}
iframe#background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.4;
  background-color: white;
  z-index: -1;
  border: 0;
}
.background iframe#background {
  opacity: 1;
  z-index: 0;
}
.controls {
  position: absolute;
  top: 1em;
  left: calc(50% - 4.5em);
  width: 9em;
  text-align: center;
  -webkit-animation: slideInDown 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards, fadeIn 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  animation: slideInDown 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards, fadeIn 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}
.background .controls {
  z-index: 0;
  -webkit-animation: slideOutUp 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards, fadeOut 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
  animation: slideOutUp 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards, fadeOut 1s cubic-bezier(0.68, -0.55, 0.265, 1.55) forwards;
}
.controls a {
  display: inline-block;
  border-radius: 50%;
  text-transform: lowercase;
  text-decoration: none;
  margin: auto;
  text-align: center;
  z-index: 100000000;
  background: none;
  color: white;
  width: 3em;
  line-height: 3em;
  cursor: pointer;
}
#play_btn {
  background: #fff;
  color: inherit;
}
@keyframes slideOutUp {
  from {
    z-index: 10000000;
  }
  to {
    z-index: 0;
    transform: translateY(-100px);
  }
}
@keyframes slideInDown {
  from {
    transform: translateY(-100px);
  }
}
